import { BrowserRouter } from "react-router-dom";
import {
  ChallengeButton,
  Container,
  Dropdown,
  StyledDropdownItem,
} from "./styles";
import ScrollAnimation from "react-animate-on-scroll";
import { NavHashLink } from "react-router-hash-link";
import linkedin from "../../assets/linkedin.svg";
import githubIcon from "../../assets/github.svg";
import Hello from "../../assets/Hello.gif";
import Wave from "../../assets/waving-cartoon.png";
import { useEffect, useRef, useState } from "react";
import MediumIcon from "../../assets/Medium-Icon.jpg"


export function Hero() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Use a ref to handle outside clicks for closing the dropdown
  const dropdownRef = useRef<any>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
  return (
    <Container id="home">
      <div className="hero-text">
        <ScrollAnimation animateIn="fadeInUp">
          <p>
            Howdy <img src={Hello} alt="Hello" width="20px" style={{"marginRight":"5px"}}/> {" "}I'm
          </p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={0.2 * 1000}>
          <h1>Dan Tulpa</h1>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={0.4 * 1000}>
          <h3>Full Stack Engineer</h3>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={0.6 * 1000}>
          <p className="small-resume">4 Years of Experience</p>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeInUp" delay={0.8 * 1000}>
          <BrowserRouter>
            <NavHashLink smooth to="#contact" className="button">
              Contact
            </NavHashLink>
          </BrowserRouter>
        </ScrollAnimation>

        {/* socials  */}
        <ScrollAnimation animateIn="fadeInUp" delay={1 * 1000}>
          <div className="social-media">
            <a
              href="https://www.linkedin.com/in/daniel-tulpa/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={linkedin} alt="Linkedin" />
            </a>
            <a
              href="https://github.com/dtulpa16"
              target="_blank"
              rel="noreferrer"
            >
              <img src={githubIcon} alt="GitHub" />
            </a>
            <a
              href="https://medium.com/@dtulpa16"
              target="_blank"
              rel="noreferrer"
            >
              <img src={MediumIcon} alt="Medium" style={{"borderRadius":"100%", "height":"5.5rem"}} />
            </a>
          </div>
        </ScrollAnimation>

        {/* Coding challenge  */}
        <ScrollAnimation animateIn="fadeInUp" delay={1.2 * 1000}>
          <BrowserRouter>
            <div className="challenge-cta">
              <ChallengeButton onClick={toggleDropdown}  dropdownActive={isDropdownOpen}>
                Try my Coding Challenges!
              </ChallengeButton>
              <Dropdown className={isDropdownOpen ? "active" : ""}>
                {/* <StyledDropdownItem to="/challenge/noobie">
                  Noobie Mode
                </StyledDropdownItem> */}
                <StyledDropdownItem href="https://strong-toffee-cf38d8.netlify.app/challenge/easy">
                  Easy Mode 
                </StyledDropdownItem>
                <StyledDropdownItem href="https://strong-toffee-cf38d8.netlify.app/challenge/medium">
                  Medium Mode 
                </StyledDropdownItem>
                <StyledDropdownItem href="https://strong-toffee-cf38d8.netlify.app/challenge/advanced">
                  Jedi Mode
                </StyledDropdownItem>
              </Dropdown>
            </div>
          </BrowserRouter>
        </ScrollAnimation>
      </div>

      <div className="hero-image">
        <ScrollAnimation animateIn="fadeInRight" delay={1 * 1000}>
          <img src={Wave} alt="Bitmoji Wave" />
        </ScrollAnimation>
      </div>
    </Container>
  );
}
