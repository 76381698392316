import { Container } from "./styles";
import externalLink from "../../assets/external-link.svg";
import ScrollAnimation from "react-animate-on-scroll";
import IronBPIcon from "../../assets/IronBP.jpg";
import MediumCloneIcon from "../../assets/MediumIcon.png";
import ProjectIcon from "./ProjectIcon";
import SummitTrackerIcon from "../../assets/adaptive-icon.png";
import MovieMania from "../../assets/MovieMania.png";
import Jaunt from "../../assets/jaunticon.jpg";
import QQ from "../../assets/qq.png";
import FlexBot from "../../assets/Screenshot_1.png";
import TaskManager from "../../assets/task.jpg";
import { useState } from "react";
import TaskManagerDemoModal from "./TaskManagerDemoModal";
import CodeCompass from "../../assets/code-compass.jpg";
import DevQuestLogo from "../../assets/dq-logo.png";
export function Project() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Container id="project">
        <h2>My Projects</h2>
        <div className="projects">
          <ScrollAnimation animateIn="flipInX">
            <div className="project">
              <header>
                <ProjectIcon ImageSrc={DevQuestLogo} />
                <div className="project-links">
                  <a
                    href="https://devquestcareers.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={externalLink} alt="Visit Dev Quest" />
                  </a>
                </div>
              </header>
              <div className="body">
                <h3>
                  DevQuest -{" "}
                  <span style={{ fontStyle: "italic", color: "#f7df1e" }}>
                    Current WIP
                  </span>
                </h3>
                <p>
                  An AI intensive platform aimed to get people in tech fired
                  faster. DevQuest transforms the daunting task of tech job
                  preparation into an engaging journey with personalized mock
                  interviews, real-time coding challenges, and a sleek job
                  search feature—all designed to sharpen your skills and land
                  your dream job in the tech industry.
                </p>
              </div>
              <footer>
                <ul className="tech-list">
                  <li>Next.js</li>
                  <li>Supabase</li>
                  <li>OpenAI API</li>
                  <li>OAuth v2</li>
                  <li>Postman</li>
                </ul>
              </footer>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="flipInX">
            <div className="project">
              <header>
                <ProjectIcon ImageSrc={IronBPIcon} />
                <div className="project-links">
                  <a
                    href="https://theironblueprint.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={externalLink} alt="Visit site" />
                  </a>
                </div>
              </header>
              <div className="body">
                <h3>Iron Blueprint</h3>
                <p>
                  An AI-enhanced fitness app for tracking workouts, gaining
                  in-depth nutritional insights, and so much more!
                </p>
              </div>
              <footer>
                <ul className="tech-list">
                  <li>Next.js</li>
                  <li>Typescript</li>
                  <li>Tailwindcss</li>
                  <li>ASP.NET Core</li>
                  <li>MySQL</li>
                  <li>Azure</li>
                  <li>OpenAI</li>
                </ul>
              </footer>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="flipInX">
            <div className="project">
              <header>
                <ProjectIcon ImageSrc={CodeCompass} />
                <div className="project-links">
                  <a
                    href="https://code-compass-ai.carrd.co/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={externalLink} alt="Visit Code Compass" />
                  </a>
                </div>
              </header>
              <div className="body">
                <h3>
                  Code Compass -{" "}
                  <span style={{ fontStyle: "italic", color: "#23ce6b" }}>
                    Newest Release!
                  </span>
                </h3>
                <p>
                  An advanced Slack-integrated application that makes use of
                  ChatGPT-4 AI&#39;s sophisticated capabilities to give
                  developers accurate, real-time debugging support, transforming
                  the software development process. Now with Streamed Responses
                  directly in Slack!
                </p>
              </div>
              <footer>
                <ul className="tech-list">
                  <li>Express.js</li>
                  <li>Slack API</li>
                  <li>Open AI API</li>
                  <li>Ngrok</li>
                  <li>Postman</li>
                </ul>
              </footer>
            </div>
          </ScrollAnimation>
          
          <ScrollAnimation animateIn="flipInX">
            <div className="project">
              <header>
                <ProjectIcon ImageSrc={MediumCloneIcon} />
                <div className="project-links">
                  <a
                    href="https://dtulpa-medium-clone.netlify.app/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={externalLink} alt="Visit site" />
                  </a>
                </div>
              </header>
              <div className="body">
                <h3>Medium News Clone</h3>
                <p>
                  A clone of Medium's news platform. My goal with this
                  application was to mimic the styles of their platform only
                  using Tailwind CSS.
                </p>
              </div>
              <footer>
                <ul className="tech-list">
                  <li>Next.js</li>
                  <li>Typescript</li>
                  <li>Tailwindcss</li>
                </ul>
              </footer>
            </div>
          </ScrollAnimation>

          <ScrollAnimation animateIn="flipInX">
            <div className="project">
              <header>
                <ProjectIcon ImageSrc={SummitTrackerIcon} />
                <div className="project-links">
                  <a
                    href="https://summittracker.netlify.app/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={externalLink} alt="Visit site" />
                  </a>
                </div>
              </header>
              <div className="body">
                <h3>Summit Tracker - My personal hike tracker</h3>
                <p>
                  My personal diary for hiking exploits, capturing the highs and
                  lows, the distances, and special moments with notes and
                  pictures.
                </p>
              </div>
              <footer>
                <ul className="tech-list">
                  <li>React.js</li>
                  <li>Typescript</li>
                  <li>Tailwindcss</li>
                  <li>MongoDb</li>
                  <li>Express.js</li>
                </ul>
              </footer>
            </div>
          </ScrollAnimation>

          <ScrollAnimation animateIn="flipInX">
            <div className="project">
              <header>
                <ProjectIcon ImageSrc={MovieMania} />
                <div className="project-links">
                  <a
                    href="https://playful-tarsier-997ef8.netlify.app/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={externalLink} alt="Visit site" />
                  </a>
                </div>
              </header>
              <div className="body">
                <h3>MovieMania - Movie Browsing Application</h3>
                <p>
                  Offers a user-friendly and responsive interface to search for
                  movies and TV shows, all while remaining on a single page.
                  Users can optionally Sign In & Register with Google.
                </p>
              </div>
              <footer>
                <ul className="tech-list">
                  <li>React.js</li>
                  <li>Tailwindcss</li>
                  <li>Firebase Auth</li>
                  <li>Postman</li>
                </ul>
              </footer>
            </div>
          </ScrollAnimation>

          <ScrollAnimation animateIn="flipInX">
            <div className="project">
              <header>
                <ProjectIcon ImageSrc={Jaunt} />
                <div className="project-links">
                  <a
                    href="https://youtu.be/pSYGbuiRWes?si=Yr-UVstLQlDzYGXU"
                    // href="http://ec2-34-204-82-92.compute-1.amazonaws.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={externalLink} alt="Visit site" />
                  </a>
                </div>
              </header>
              <div className="body">
                <h3>Jaunt - Fitness Application</h3>
                <p>
                  Fitness application for clients and trainers that generates
                  custom workout and diet plans based on physical
                  characteristics and fitness goals.
                </p>
              </div>
              <footer>
                <ul className="tech-list">
                  <li>React.js</li>
                  <li>MUI</li>
                  <li>Django</li>
                  <li>MySQL</li>
                  <li>Selenium</li>
                  <li>AWS</li>
                  <li>Bootstrap</li>
                </ul>
              </footer>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="flipInX">
            <div className="project">
              <header>
                <ProjectIcon ImageSrc={TaskManager} />
                <div onClick={handleOpen} className="project-links">
                  <div>
                    <img src={externalLink} alt="Visit site" />
                  </div>
                </div>
              </header>
              <div className="body">
                <h3>Task Manager</h3>
                <p>
                  A Slack bot that streamlines task management. The bot
                  integrates with Notion API to create and update tasks
                  automatically, saving time and effort.
                </p>
              </div>
              <footer>
                <ul className="tech-list">
                  <li>Express.js</li>
                  <li>Slack API</li>
                  <li>Notion API</li>
                  <li>Open AI API</li>
                  <li>Ngrok</li>
                  <li>Postman</li>
                </ul>
              </footer>
            </div>
          </ScrollAnimation>

          <ScrollAnimation animateIn="flipInX">
            <div className="project">
              <header>
                <ProjectIcon ImageSrc={QQ} />
                <div className="project-links">
                  <a
                    href="https://github.com/dtulpa16/Question_Queue_Slack_Bot/blob/main/README.md"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={externalLink} alt="Visit site" />
                  </a>
                </div>
              </header>
              <div className="body">
                <h3>Question Queue Bot</h3>
                <p>
                  A Slack bot designed to streamline the process of students
                  seeking help from the Instructor Team.
                </p>
              </div>
              <footer>
                <ul className="tech-list">
                  <li>Express.js</li>
                  <li>Slack API</li>
                  <li>Notion API</li>
                  <li>Open AI API</li>
                  <li>MongoDb</li>
                  <li>Ngrok</li>
                  <li>Postman</li>
                </ul>
              </footer>
            </div>
          </ScrollAnimation>

          <ScrollAnimation animateIn="flipInX">
            <div className="project">
              <header>
                <svg
                  width="50"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#23ce6b "
                  stroke-width="1"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <title>Folder</title>
                  <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
                </svg>
                <div className="project-links">
                  <a
                    href="https://calm-crumble-fb5302.netlify.app/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={externalLink} alt="Visit site" />
                  </a>
                </div>
              </header>
              <div className="body">
                <h3>Portfolio Site V1</h3>
                <p>Version 1 of my personal portfolio site</p>
              </div>
              <footer>
                <ul className="tech-list">
                  <li>React.js</li>
                  <li>Tailwindcss</li>
                </ul>
              </footer>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="flipInX">
            <div className="project">
              <header>
                <ProjectIcon ImageSrc={FlexBot} />
                <div className="project-links">
                  <a
                    href="https://github.com/dtulpa16"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={externalLink} alt="Visit site" />
                  </a>
                </div>
              </header>
              <div className="body">
                <h3>Flex Bot</h3>
                <p>
                  An efficient tool for instructors to manage student meetings
                  and track progress using Slack and Notion Database.
                </p>
              </div>
              <footer>
                <ul className="tech-list">
                  <li>Express.js</li>
                  <li>Slack API</li>
                  <li>Notion API</li>
                  <li>Open AI API</li>
                  <li>MongoDb</li>
                  <li>Ngrok</li>
                  <li>Postman</li>
                </ul>
              </footer>
            </div>
          </ScrollAnimation>
        </div>
      </Container>
      <TaskManagerDemoModal open={open} handleClose={handleClose} />
    </>
  );
}
