import styled, { keyframes, css } from "styled-components";

const wiggle = keyframes`
  0%, 100% { transform: rotate(0deg); }
  10% { transform: rotate(-1.1deg); }
  20% { transform: rotate(1.3deg); }
  30% { transform: rotate(-1.5deg); }
  40% { transform: rotate(1.3deg); }
  50% { transform: rotate(0deg); }
`;

export const Container = styled.section`
  padding-top: 15%;
  display: flex;
  position: relative;
  justify-content: space-between;
  gap: 8rem;
  background: rgba(0, 0, 0, 0);
  .hero-text {
    & > p {
      font-size: 1.8rem;
    }
    h1 {
      font-size: 7rem;
    }

    h3 {
      color: var(--green);
      margin: 1rem 0;
    }

    p.small-resume {
      margin-bottom: 5rem;
    }
  }
  .social-media {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding-top: 5rem;
    padding-left: 1rem;

    img,
    span {
      font-size: 3rem;
      width: 5.5rem;
    }
  }
  .challenge-cta {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    width: fit-content;
    align-items: center;
  }
  .button {
    margin-top: 5rem;
    padding: 1.4rem 6rem;
  }

  .hero-image {
    img {
      max-width: 500px;
      max-height: 500px;
    }
  }

  @media (max-width: 960px) {
    display: block;
    margin-top: 15%;
    .hero-text {
      h1 {
        font-size: 5rem;
      }
    }

    .hero-image {
      display: none;
    }
  }

  @media (max-width: 600px) {
    margin-top: 35%;
  }
  @media (max-width: 480px) {
    margin-top: 45%;
  }
`;

export const StyledDropdownItem = styled.a`
  display: block;
  text-align: center;
  padding: 0.75rem 1.5rem;
  text-decoration: none;
  color: #333;
  &:hover {
    background-color: #f5f5f5;
  }
`;

type ChallengeButtonProps = {
  dropdownActive: boolean;
};

const wiggleAnimation = css`
  ${wiggle} 3.1s ease-in-out infinite
`;
export const ChallengeButton = styled.button<ChallengeButtonProps>`
  border: none;
  margin-top: 4rem;
  padding: 1.4rem 5rem;
  cursor: pointer;
  background-color: var(--pink);
  color: #ffff;
  position: relative;
  border-radius: 2rem;
  font-weight: 500;
  font-size: 1.8rem;
  transition: filter 0.25s;
  animation: ${({ dropdownActive }) => !dropdownActive && wiggleAnimation};
  &:hover {
    filter: brightness(0.8);
  }
`;
export const Dropdown = styled.div`
  display: none;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
  width: 95%;
  &.active {
    display: block;
    animation: fadeIn 0.3s ease-in-out;
  }
  &.active > ${ChallengeButton} {
    animation: none;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px) translateX(0%);
    }
    to {
      opacity: 1;
      transform: translateY(0) translateX(0%);
    }
  }
`;
