import React from "react";

export default function ProjectIcon({ ImageSrc }: any) {
  return (
    <img
      alt="Iron Blueprint"
      src={ImageSrc}
      width="55"
      style={{
        borderRadius: "50%",
        marginBottom: "-1.8rem",
        marginTop:"-.7rem",
        padding: "1px",
        backgroundColor: "white",
        boxShadow:
          "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
        maxWidth: "120px",
        objectFit: "contain",
      }}
    />
  );
}
