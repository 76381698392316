import TaskManagerVid from "../../assets/TaskManagerDemo.mp4";
import { ModalContainer } from "./styles";
type ModalProps = {
  open: boolean;
  handleClose:()=>void
};
export default function TaskManagerDemoModal({ open, handleClose }: ModalProps) {
  return (
    <ModalContainer>
      {open && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-modal-btn" onClick={handleClose}>
              &times;
            </span>
            <video width="100%" height="auto" controls>
              <source src={TaskManagerVid} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      )}
    </ModalContainer>
  );
}
