import { Container } from "./styles";
import DanTulpa from "../../assets/MainHeadshot.jpg";
import MongoDbIcon from "../../assets/mongodb.jpg";
import cssIcon from "../../assets/css-icon.svg";
import jsIcon from "../../assets/js-icon.svg";
import nodeIcon from "../../assets/node-icon.svg";
import reactIcon from "../../assets/react-icon.svg";
import typescriptIcon from "../../assets/typescript-icon.svg";
import TailwindIcon from "../../assets/TailwindIcon.jpg";
import boostrapIcon from "../../assets/bootstrap-icon.svg";
import ScrollAnimation from "react-animate-on-scroll";
import NextIcon from "../../assets/NextIcon.jpg";

export function About() {
  return (
    <Container id="about">
      <ScrollAnimation className="about-text" animateIn="fadeInLeft">
        <ScrollAnimation animateIn="fadeInLeft">
          <h2>About me</h2>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.1 * 1000}>
          <p>
            Hi there! I'm Dan, a Full-Stack developer with a passion for
            creating custom modern, responsive, and efficient web applications.
          </p>
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="fadeInLeft"
          delay={0.2 * 1000}
          style={{ marginTop: "2rem", marginBottom: "2rem" }}
        >
          <p>
            I'm a Wisconsin born software developer currently based in the
            scenic town of Fort Collins, Colorado.
          </p>
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="fadeInLeft"
          delay={0.3 * 1000}
          style={{ marginTop: "2rem", marginBottom: "2rem" }}
        >
          <p>
            I proudly served as an Electronics Technician in the US Navy, and am
            currently searching for new opportunities to apply my experience and
            passion for technology to the creation of effective and powerful
            software.
          </p>
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="fadeInLeft"
          delay={0.4 * 1000}
          style={{ marginTop: "2rem", marginBottom: "2rem" }}
        >
          <>
            <p>Some of my proudest accomplishments:</p>
            <ul>
              <li>
                <p>&gt; Breaking the 2012 Wisconsin Brown Trout Record🎣</p>
              </li>
              <li>
                <p>&gt; Losing over 150lbs💪</p>
              </li>
              <li>
                <p>
                  &gt; Hiking over 130 miles & 30k feet of elevation in 2023🏔️
                </p>
              </li>
            </ul>
          </>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.5 * 1000}>
          <p>
            I'm passionate about anything and everything outdoors, fitness,
            self-improvement, sports, and tech!
          </p>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeInLeft" delay={0.5 * 1000}>
          <h3>Whats in my Toolbox:</h3>
        </ScrollAnimation>
        <div className="hard-skills">
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.5 * 1000}>
              <img src={reactIcon} alt="React" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.5 * 1000}>
              <img src={NextIcon} alt="Nextjs" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.5 * 1000}>
              <img src={typescriptIcon} alt="Typescript" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.5 * 1000}>
              <img src={nodeIcon} alt="Node" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.5 * 1000}>
              <img src={TailwindIcon} alt="Tailwindcss" />
            </ScrollAnimation>
          </div>

          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.5 * 1000}>
              <img src={cssIcon} alt="Css" />
            </ScrollAnimation>
          </div>

          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.5 * 1000}>
              <img src={MongoDbIcon} alt="MongoDb" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.5 * 1000}>
              <img src={jsIcon} alt="JavaScript" />
            </ScrollAnimation>
          </div>
        </div>
      </ScrollAnimation>
      <div className="about-image">
        <ScrollAnimation animateIn="fadeInRight" delay={0.2 * 1000}>
          <img src={DanTulpa} alt="Dan Tulpa" />
        </ScrollAnimation>
      </div>
    </Container>
  );
}
