import styled from "styled-components";

export const Container = styled.section`
  margin-top: 15rem;
  
  h2{
    text-align: center;
    font-size: 4rem;
    margin-bottom: 3rem;
  }
  
  .projects{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    gap: 2rem;
    padding: 1rem;
    overflow: hidden;

    .project{
        padding: 2rem 1.8rem;
        background-color: #2b2b2b;
        border-radius: 1.2rem;
        transition: 0.25s;
        display: flex;
        flex-direction: column;
        height: 100%;
        color: #FFF;
        &:hover{
            transform: translateY(-5px);
            background-color: var(--pink);
        }
    }

      header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--blue);
        margin-bottom: 3.6rem;
        .project-links{
          display: flex;
          align-items: center;
          gap: 1rem;
        }
        a > img {
          width: 5.0rem;
        }
        div > img {
          width: 5.0rem;
        }
      }
      
      h3{
        margin-bottom: 2rem;
      }

      p{
        letter-spacing: 0.12rem;
        margin-bottom: 2rem;
        a{
          color: #FFFF;
          border-bottom: 1px solid var(--green);
          transition: color 0.25s;
          &:hover{
            color: var(--green);
          }
        }
      }

      footer{
        margin-top: auto;
        .tech-list{
          display: flex;
          align-items: center;
          gap: 2rem;
          font-size: 1.4rem;
          opacity: 0.6;
        }
      }

    }
  }

  @media (max-width: 1600px){
    .projects{
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (max-width: 1250px){
    .projects{
      grid-template-columns: repeat(1, 1fr);
    }
  }


  @media (max-width: 740px){
    .projects{
      grid-template-columns: 1fr;
      overflow:auto;
    }
    .project{
      width:100%;
    }
  }

  @media (max-width: 480px) {
      .projects {
          display:flex;
          flex-direction:column
      }
      footer{
        .tech-list{
          flex-wrap:wrap;
        }
      }
  }
`;
export const ModalContainer = styled.section`
    .modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
    }

    .modal-content {
        position:relative;
        background: white;
        padding: 10px;
        border-radius: 4px;
        max-width: 90%;
        max-height: 90%;
        overflow-y: auto;
    }

    .close-modal-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 30px;
        color: #ffffff;
        cursor: pointer;
        background-color:#000000;
        padding:.1rem .6rem;
        z-index:2
    }

    .open-modal-btn {
        padding: 10px 20px;
        background-color: blue;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
    }

    @media (min-width: 768px) {
        .modal-content {
            max-width: 50%;
        }
    }
`;
